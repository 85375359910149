.w-Fixed-10em{
  width: 10em !important;
}

.w-Fixed-15em{
  width: 15em !important;
}

.search-width1 {
 width:6em;
}

.input-width1{
  width:10em;
}
.input-width2{
  width:27em;
}

.detail-width{
  width:10px;
}

.bg-yellow{
  background-color:yellow;
}